import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Incentive } from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/incentive';
import { getAllQualifierDescriptions } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-all-qualifier-descriptions';

import './qualifier-description.scss';

export function QualifierDescription({ incentives }) {
  const options = useMemo(() => getAllQualifierDescriptions(incentives), [incentives]);
  return (
    <div className="pos-r">
      <div className="qualifier-description rounded-12 bg-cool-gray-100 size-14 text-cool-gray-30 pt-1 px-1 mb-1_5">
        <ul className="m-0 pl-1">
          {options.map(option => (
            <li key={option} className="pb-1">
              {option}
            </li>
          ))}
        </ul>
      </div>
      {options.length > 4 && <div className="gradient pos-a" />}
    </div>
  );
}

QualifierDescription.propTypes = {
  incentives: PropTypes.arrayOf(Incentive).isRequired,
};
