import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// Components
import { Link } from 'site-modules/shared/components/link/link';

export const WizardDisclaimer = memo(({ classes }) => (
  <div className={classnames('d-flex', classes)}>
    <span className="icon-shield-check text-cool-gray-50 size-24 mr-0_5" aria-hidden />
    <div>
      <span className="text-cool-gray-40">
        Edmunds does not share any data that you provide through this tool. We make every effort to provide accurate
        information, but please verify programs and eligibility before purchasing a vehicle. This information is
        provided subject to our{' '}
      </span>
      <Link className="text-primary-darker" to="/about/visitor-agreement.html">
        Visitor Agreement
      </Link>
      <span className="text-cool-gray-40">.</span>
    </div>
  </div>
));

WizardDisclaimer.displayName = 'WizardDisclaimer';
WizardDisclaimer.propTypes = {
  classes: PropTypes.string,
};
WizardDisclaimer.defaultProps = {
  classes: 'small mt-1',
};
