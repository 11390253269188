import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { BulletItem } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/components/bullet-item';

import './bullet-list.scss';

export function BulletsList({ config, className, listTitle, listTitleClassName, withAnimation }) {
  if (!config.length) return null;
  return (
    <div className={classnames('bullet-list', className)}>
      {listTitle && <div className={listTitleClassName}>{listTitle}</div>}
      <ul className={classnames('list-unstyled mb-0', { animation: withAnimation })}>
        {config.map(({ id, ...rest }) => (
          <BulletItem key={id} withAnimation={withAnimation} {...rest} />
        ))}
      </ul>
    </div>
  );
}

BulletsList.propTypes = {
  config: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      iconClassName: PropTypes.string,
      titleClassName: PropTypes.string,
    })
  ),
  className: PropTypes.string,
  listTitle: PropTypes.string,
  listTitleClassName: PropTypes.string,
  withAnimation: PropTypes.bool,
};

BulletsList.defaultProps = {
  config: [],
  className: null,
  listTitle: null,
  listTitleClassName: null,
  withAnimation: false,
};
