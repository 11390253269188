import { get } from 'lodash';
import { ASSISTANCE } from 'site-modules/shared/components/incentives/incentives-wizard/constants/qualifiers';

export const getAllQualifierDescriptions = incentives => {
  if (!incentives) {
    return [];
  }
  const incentivesWithQualifierDescription = incentives.filter(incentive => {
    const rebateOverridesByEligibility = get(incentive, 'rebateOverridesByEligibility', []);
    if (!rebateOverridesByEligibility.length) return false;
    return rebateOverridesByEligibility.some(
      ({ eligibilityFactor }) => eligibilityFactor.qualifier?.toLowerCase() === ASSISTANCE
    );
  });

  return Array.from(
    new Set(
      incentivesWithQualifierDescription
        .reduce((result, incentive) => {
          const descriptions = incentive.rebateOverridesByEligibility
            .map(({ eligibilityFactor }) => eligibilityFactor.qualifierDescription)
            .filter(qualifierDescription => !!qualifierDescription);

          return result ? `${result};${descriptions.join(';')}` : descriptions.join(';');
        }, '')
        .split(';')
        .filter(description => !!description)
    )
  );
};
