import React from 'react';
import PropTypes from 'prop-types';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { IncentivesWizardModel, IncentivesWizardPaths } from 'client/data/models/incentives-wizard';
import { SOURCES } from 'client/utils/image-helpers';
import { getNoVehicleImageToDisplay } from 'site-modules/shared/utils/get-no-vehicle-image-to-display';
import { Image } from 'site-modules/shared/components/image/image';

import './wizard-image.scss';

export function WizardImageUI({ href, bodyType }) {
  const errorImage = getNoVehicleImageToDisplay(bodyType);

  return (
    <div className="wizard-img-wrapper pos-a mx-auto bg-white overflow-hidden text-center bg-white rounded-16 p-0_5">
      <Image imageId={href} source={SOURCES.MEDIA} alt="" errorImage={errorImage} className="rounded-12" />
    </div>
  );
}

WizardImageUI.propTypes = { href: PropTypes.string, bodyType: PropTypes.string };
WizardImageUI.defaultProps = { href: '', bodyType: '' };

export const stateToPropsConfig = {
  href: bindToPath(IncentivesWizardPaths.getWizardPhoto(), IncentivesWizardModel),
};
export const WizardImage = connectToModel(WizardImageUI, stateToPropsConfig);
