import PropTypes from 'prop-types';

export const Incentive = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  programType: PropTypes.string.isRequired,
  subtype: PropTypes.string.isRequired,
  subtypeId: PropTypes.number.isRequired,
  rebateAmount: PropTypes.number,
  endDate: PropTypes.string,
  updateDate: PropTypes.string,
  grantor: PropTypes.string,
  restrictions: PropTypes.string.isRequired,
  transactionTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  compatibleIncentiveIds: PropTypes.arrayOf(PropTypes.string),
  primary: PropTypes.bool,
  programUrl: PropTypes.string,
});
