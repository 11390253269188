import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Question } from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/question';
import { Incentive } from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/incentive';
import { Context } from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/context';

export const StepProps = {
  stepConfig: Question.isRequired,
  stepMeta: PropTypes.shape({}),
  answers: PropTypes.shape({}),
  eligibleIncentiveIds: PropTypes.arrayOf(PropTypes.string),
  incentives: PropTypes.arrayOf(Incentive),
  allIncentives: PropTypes.arrayOf(Incentive).isRequired,
  onAnswer: PropTypes.func,
  onFinish: PropTypes.func,
  onBack: PropTypes.func,
  context: Context,
  onExit: PropTypes.func,
  flowSteps: PropTypes.arrayOf(PropTypes.string),
  creativeId: PropTypes.string,
  activeCategory: PropTypes.string,
};

export const StepPropsDefaults = {
  answers: {},
  eligibleIncentiveIds: [],
  context: {},
  stepMeta: {},
  incentives: [],
  onAnswer: noop,
  onBack: noop,
  onFinish: noop,
  flowSteps: [],
  creativeId: undefined,
  activeCategory: undefined,
};
