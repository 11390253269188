import { CATEGORIES } from 'site-modules/shared/components/incentives/incentives-wizard/constants/categories';

export const IMAGES = {
  [CATEGORIES.FEDERAL]: 'img/wizard/gov.svg',
  [CATEGORIES.LOCAL]: 'img/wizard/house.svg',
};

export const ICONS_MAP = {
  check: 'icon-circle',
  success: 'icon-checkmark-circle text-green-50',
  failure: 'icon-cancel-circle2 text-cool-gray-50',
};

export const ELIGIBILITY_FIELD_MAP_TARGET = {
  DISPLAY: 'display',
  FILTER: 'filter',
};
