import PropTypes from 'prop-types';

export const Question = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.func,
  description: PropTypes.string,
  variant: PropTypes.oneOf(['question', 'screen']),
  type: PropTypes.oneOf([
    'radioList',
    'input',
    'binary',
    'zip',
    'incentivesList',
    'custom',
    'start',
    'result',
    'eligibility',
    'informative',
    'checking-eligibility',
  ]),
  required: PropTypes.bool,
  autoCloseAfter: PropTypes.number,
  customConfig: PropTypes.shape({
    componentName: PropTypes.string,
    component: PropTypes.elementType,
  }),
  autoAnswer: PropTypes.arrayOf(PropTypes.string),
  answerOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
      })
    ),
    PropTypes.func,
  ]),
  inputAriaLabel: PropTypes.string,
  hint: PropTypes.shape({
    text: PropTypes.func,
    expandedSummary: PropTypes.string,
    collapsedSummary: PropTypes.string,
  }),
});
