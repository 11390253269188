import { CATEGORIES } from 'site-modules/shared/components/incentives/incentives-wizard/constants/categories';
import { isFederalRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-federal-rebate';
import { isLocalEvRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-local-ev-rebate';
import { isTaxCreditRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-tax-credit-rebate';
import { isChargerInstallationRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-charger-installation-rebate';

export const checkIncentiveBelongsToCategory = (category, incentive) => {
  const categoryCheckersMap = {
    [CATEGORIES.FEDERAL]: [isFederalRebate],
    [CATEGORIES.LOCAL]: [isLocalEvRebate, isTaxCreditRebate, isChargerInstallationRebate],
  };

  return categoryCheckersMap[category].some(checker => !!checker(incentive));
};
