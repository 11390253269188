import { get, uniqBy } from 'lodash';
import { questions } from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';
import { ELIGIBILITY } from 'site-modules/shared/components/incentives/incentives-wizard/constants/screen-types';
import { ICONS_MAP } from 'site-modules/shared/components/incentives/incentives-wizard/constants/eligibility';
import { checkCategoryIncentivesEligible } from 'site-modules/shared/components/incentives/incentives-wizard/utils/check-eligibility';
import classnames from 'classnames';
import { CATEGORIES } from 'site-modules/shared/components/incentives/incentives-wizard/constants/categories';
import { isOEMRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-oem-rebate';
import { checkIncentiveBelongsToCategory } from 'site-modules/shared/components/incentives/incentives-wizard/utils/check-incentive-belongs-to-category';
import { PUB_STATES_LOWERCASE } from 'client/constants/pub-states';

const getCategoriesFromFlow = flowSteps =>
  Array.from(
    new Set(
      flowSteps
        .filter(stepId => get(questions[stepId], 'type') === ELIGIBILITY)
        .map(stepId => get(questions[stepId], 'categories', [])[0])
    )
  );

function getStartScreenConfig({ isEligible }) {
  return { icon: isEligible ? ICONS_MAP.success : ICONS_MAP.failure, titleClassName: 'text-cool-gray-30' };
}

function getEligibilityScreenConfig({
  categoryFromFlow,
  isActiveCategory,
  eligibleIncentiveIds,
  allIncentives,
  categoriesForCalculateEligibility,
}) {
  if (categoriesForCalculateEligibility.includes(categoryFromFlow)) {
    const isEligible = checkCategoryIncentivesEligible(categoryFromFlow, eligibleIncentiveIds, allIncentives);
    const icon = isEligible ? ICONS_MAP.success : ICONS_MAP.failure;
    return {
      icon,
      titleClassName: classnames('text-cool-gray-30', { checked: !isActiveCategory }),
      accessibilityText: `${isEligible ? 'eligible' : 'ineligible'}`,
    };
  }

  return {
    icon: ICONS_MAP.check,
    iconClassName: classnames({ 'text-cool-gray-60': !isActiveCategory, 'text-cool-gray-50': isActiveCategory }),
    titleClassName: classnames('text-cool-gray-30', { current: isActiveCategory }),
    accessibilityText: 'Pending check',
  };
}

function hideBullets({ categoriesFromFlow, flowSteps, stepConfig }) {
  const { id, hideBullets: shouldHideBullets } = stepConfig;
  const firstEligibilityScreenId = flowSteps.find(stepId => get(questions[stepId], 'type') === ELIGIBILITY);
  return categoriesFromFlow.length === 1 || firstEligibilityScreenId === id || shouldHideBullets;
}

function getCategoriesForEligibilityCheck({ categoriesFromFlow, stepConfig, flowSteps }) {
  const { id: screenId, categories } = stepConfig;
  const currentStepCategory = categories && categories[0];
  const isResultScreen =
    flowSteps.filter(id => get(questions[id], 'type') === ELIGIBILITY).findIndex(id => id === screenId) % 2 === 1;
  const activeCategoryIndex = categoriesFromFlow.findIndex(category => category === currentStepCategory);
  const includeActiveCategoryIncrement = isResultScreen ? 1 : 0;

  return categoriesFromFlow.slice(0, activeCategoryIndex + includeActiveCategoryIncrement);
}

const BULLETS_CONFIG = {
  [CATEGORIES.FEDERAL]: ({ isEligible, context }) => {
    const typePart = context.type?.toLowerCase() === PUB_STATES_LOWERCASE.USED ? ' used ' : ' ';

    return {
      id: '1',
      title: isEligible ? `Federal${typePart}EV credit` : `Vehicle not eligible for federal${typePart}EV credit`,
    };
  },
  [CATEGORIES.LOCAL]: () => ({ id: '2', title: 'Local rebates' }),
  [CATEGORIES.OEM]: ({ grantor }) => {
    const grantorString = grantor || 'Automaker';
    return { id: grantorString, title: `${grantorString} rebates` };
  },
};

export function getBulletsConfig({ stepConfig, flowSteps, eligibleIncentiveIds, allIncentives, context }) {
  const { categories } = stepConfig;

  const categoriesFromFlow = getCategoriesFromFlow(flowSteps);
  const currentStepCategory = categories && categories[0];

  if (hideBullets({ categoriesFromFlow, flowSteps, stepConfig })) {
    return [];
  }

  const categoriesForCalculateEligibility = getCategoriesForEligibilityCheck({
    categoriesFromFlow,
    stepConfig,
    flowSteps,
  });

  return categoriesFromFlow.map(categoryFromFlow => {
    const config = getEligibilityScreenConfig({
      categoryFromFlow,
      isActiveCategory: currentStepCategory === categoryFromFlow,
      eligibleIncentiveIds,
      allIncentives,
      categoriesForCalculateEligibility,
    });
    return { ...BULLETS_CONFIG[categoryFromFlow]({ isEligible: true, context }), ...config };
  });
}

export function getStartScreenBulletsConfig({ allIncentives, context }) {
  const { make } = context;
  return Object.entries(BULLETS_CONFIG).reduce((result, [category, createBaseConfigCb]) => {
    if (category === CATEGORIES.OEM) {
      const oemConfig = allIncentives
        ?.filter(incentive => isOEMRebate(incentive) && (!incentive.grantor || incentive.grantor.includes(make)))
        .map(incentive => {
          const config = getStartScreenConfig({ isEligible: true });
          return { ...createBaseConfigCb({ grantor: incentive?.grantor, context }), ...config };
        });

      return [...result, ...uniqBy(oemConfig, 'id')];
    }

    if (category === CATEGORIES.FEDERAL) {
      const isEligible = allIncentives.some(incentive => checkIncentiveBelongsToCategory(category, incentive));

      return [...result, { ...createBaseConfigCb({ isEligible, context }), ...getStartScreenConfig({ isEligible }) }];
    }

    return [
      ...result,
      { ...createBaseConfigCb({ isEligible: true, context }), ...getStartScreenConfig({ isEligible: true }) },
    ];
  }, []);
}
