import PropTypes from 'prop-types';

export const Context = PropTypes.shape({
  make: PropTypes.string,
  model: PropTypes.string,
  year: PropTypes.number,
  bodyType: PropTypes.string,
  engineTypes: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
});
