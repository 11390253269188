import { checkIncentiveBelongsToCategory } from 'site-modules/shared/components/incentives/incentives-wizard/utils/check-incentive-belongs-to-category';
import { get } from 'lodash';

export function getTaxFilingStatuses(incentives, activeCategory) {
  return Array.from(
    new Set(
      incentives
        .filter(incentive => checkIncentiveBelongsToCategory(activeCategory, incentive))
        .flatMap(incentive => {
          const rebateOverridesByEligibility = get(incentive, 'rebateOverridesByEligibility', []);
          if (!rebateOverridesByEligibility.length) return [];
          return rebateOverridesByEligibility
            .map(({ eligibilityFactor }) => get(eligibilityFactor, 'taxFilingStatus'))
            .filter(taxFilingStatus => !!taxFilingStatus)
            .sort();
        })
    )
  );
}
